import React, { type ReactElement, useState } from 'react';

import { useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';

import { TopBarDesktop, TopBarMobile } from '@credit-app/shared-ui';

interface IPage {
  name: string;
  href: string;
}

interface IProps {
  logoOnly?: boolean;
  pages?: IPage[];
}

const TopBar = ({ logoOnly, pages }: IProps): ReactElement => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const top_bar_logo_link = 'https://creditapp.ca/consumers';

  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        boxShadow: 0,
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <TopBarDesktop
            logoOnly={logoOnly}
            pages={pages ?? []}
            onNavItemClick={handleCloseNavMenu}
            linkProps={{
              href: top_bar_logo_link,
              rel: 'noopener',
              target: '_blank',
            }}
            logoSrc={theme.logo}
          />
          <TopBarMobile
            logoOnly={logoOnly}
            pages={pages ?? []}
            onMenuOpen={handleOpenNavMenu}
            onMenuClose={handleCloseNavMenu}
            anchorElNav={anchorElNav}
            linkProps={{
              href: top_bar_logo_link,
              rel: 'noopener',
              target: '_blank',
            }}
            logoSrc={theme.logo}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;
