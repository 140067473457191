import { type ReactElement } from 'react';

import Container from '@mui/material/Container';
import Trans from 'next-translate/Trans';

import TopBar from '^/components/TopBar/TopBar';

const Home = (): ReactElement => {
  return (
    <Container>
      <TopBar />
      <Trans
        i18nKey="common:home"
        components={{ p: <p data-test-id="greeting" /> }}
      />
    </Container>
  );
};

export default Home;
